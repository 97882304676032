.entry-content>* {
    margin-bottom: 0 !important;
}

a {
    text-decoration: none !important;
}

.ct-search-box {
    form {
        input {
            padding-left: 25px !important;
            font-size: 14px !important;
        }
        .search-submit {
            right: 5px !important;
        }
    }
}

.is-style-outline {
    a {
        padding: 5px 20px !important;
        &:hover {
            background-color: #f87f26 !important;
            color: #FFFFFF !important;
        }
    }
}

.hero-title {
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.16);
}

.hero-pos-con {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 596px !important;
    .gb-headline {
        mark {
            font-weight: 400 !important;
        }
    }
}

.zoom-c {
    .eihe-box {
        height: 242px;
        margin-right: 6px;
        background-color: #FFFFFF !important;
        img {
            height: 100%;
            // object-fit: cover;
            object-fit: initial;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    .zoom-title {
        height: 55px;
        a {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 25px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: url(/wp-content/uploads/2023/07/Romvorm4.png);
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                z-index: -1;
            }
        }
    }
}

.c-bg-blue-left {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        width: 100vw;
        height: 100%;
        background-color: var(--paletteColor2, #004B91);
        z-index: -1;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 50vw;
        height: 100%;
        background: url(/wp-content/uploads/2023/07/Mask-Group-2.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: -1;
    }
}

.entries {
    article.post {
        .ct-image-container {
            border-width: 35px 35px 20px 35px;
            border-color: #FFFFFF;
            border-style: solid;
            // padding: 35px 35px 20px 35px;
            //border: 35px solid #FFFFFF !important;
            margin-bottom: 0 !important;
            // &:hover {
            //     img {
            //         transform: scale(1.1);
            //     }
            // }
        }
        .entry-meta {
            margin-top: 0 !important;
            margin-bottom: 3px !important;
            li.meta-author {
                font-weight: 700 !important;
                a {
                    text-decoration: underline;
                }
            }
        }
        .entry-title {
            margin-bottom: 11px !important;
        }
        .entry-button {
            font-family: 'IBM Plex Sans', Sans-Serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            text-transform: uppercase;
        }
    }
    article.machines {
        .ct-image-container {
            margin-bottom: 0 !important;
        }
        .entry-title {
            position: relative;
            margin-top: 27px !important;
            margin-bottom: 5px !important;
            padding-left: 34px;
            padding-right: 30px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 30px;
                bottom: -28px;
                width: 38px;
                height: 38px;
                background: url(/wp-content/uploads/2023/07/NewGroupe.png);
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        [data-block="hook:695"] {
            padding-left: 34px;
            padding-right: 30px;
            padding-bottom: 30px;
        }
    }
}

.post-type-archive-machines {
    #main {
        .hero-section {
            display: none;
        }
    }
}

.tax-machines_taxonomy {
    #main {
        .hero-section {
            .entry-header {
                padding-bottom: 0 !important;
            }
        }
    }
}

.style-list-css {

}

.style-buttons{
    column-gap: 25px;
}

#block-4 {
    margin-bottom: 3px !important;
}

#block-6 {
    margin-bottom: 10px !important;
}

.list-custom-taxonomy-widget {
    ul {
        li.cat-item {
            margin-bottom: 0 !important;
            a {
                position: relative;
                line-height: 38px !important;
                padding-bottom: 5px;
                &:hover {
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #f87f26;
                    }
                }
            }
        }
        li.current-cat {
            a {
                color: #f87f26;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #f87f26;
                }
            }
        }
    }
}

.c-shadow {
    box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.16);
}

.gform_wrapper {
    .gform-body {
        .gform_fields {
            grid-column-gap: 9% !important;
            .gfield {
                label {
                    font-family: Roboto, Sans-Serif !important;
                    font-weight: 500 !important;
                    font-size: 20px !important;
                    line-height: 26px !important;
                    color: #004B91 !important;
                }
                input {

                }
            }
        }
    }
    .gform_footer {
        
    }
}

#gform_wrapper_4 {
    margin-top: 25px;
    .gform_required_legend {
        display: none;
    }
    form {
        display: flex;
        align-items: center;
        @media screen and (max-width: 450px) {
            flex-direction: column;
        }
        .gform_body {
            width: 45%;
            input {
                height: 45px;
                border: 0 !important;
                border-radius: 5px 0 0 5px!important;
            }
            @media screen and (max-width: 767px) and (min-width: 450px) {
                width: 100% !important;
                margin-right: 15px;
            }
            @media screen and (max-width: 450px) {
                width: 100% !important;
                margin-right: 0;
            }
        }
        .gform_footer {
            width: 127px;
            padding: 0;
            margin: 0;
            input[type='submit'] {
                width: 100%;
                height: 45px;
                margin: 0;
                font-family: Raleway, Sans-Serif;
                line-height: initial !important;
                text-transform: none !important;
                border-radius: 0 5px 5px 0 !important;
            }
            @media screen and (max-width: 767px) {
                width: auto;
                input[type='submit'] {
                    margin: 0;
                }
            }
            @media screen and (max-width: 450px) {
                width: 100% !important;
                margin-top: 15px;
            }
        }
    }
}

.single-machines {
    .wp-block-post-title {
        margin-top: 10px;
        margin-bottom: 5px;
    }
} 

.toms-image-slider-preview {
    position: relative;
    .left-arrow {
        position: absolute;
        left: 0;
        bottom: 55px;
        svg {
            display: none;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -15px;
            width: 38px;
            height: 38px;
            background: url(/wp-content/uploads/2023/07/NewGroupe_1.png);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transform: rotate(180deg);
            cursor: pointer;
        }
    }
    .right-arrow {
        position: absolute;
        right: 0;
        bottom: 55px;
        svg {
            display: none;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            right: -15px;
            width: 38px;
            height: 38px;
            background: url(/wp-content/uploads/2023/07/NewGroupe_1.png);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
    .toms-image-slider-image {
        .toms-image-slider-active-image {
            width: 100%;
        }
    }
    .toms-image-slider-thumnbail {
        margin-top: 32px;
        column-gap: 15px;
        span {
            padding: 13px !important;
            border: 1px solid #848484;
            border-radius: 5px;
            width: 23.588% !important;
            img {

            }
        }
    }
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
    }
}

article>.entry-content .container-pos {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
        width: var(--block-width, var(--container-width));
    }
}

@media (min-width: 690px) and (max-width: 999.98px) {
    #main {
        .ct-container {
            .ct-hidden-md {
                display: block !important;
                grid-row-start: 1;
                grid-row-end: 2;
            }
        }
    }
}

@media (max-width: 689.98px) {
    #main {
        .ct-container {
            .ct-hidden-md {
                display: block !important;
                grid-row-start: 1;
                grid-row-end: 2;
            }
        }
    }
}

@include break-below(md) {
    .hero-home {
        min-height: 500px !important;
    }
    .hero-pages {
        min-height: 400px !important;
    }
    .c-bg-blue-left {
        &:after {
            width: 100vw;
        }
    }
}

@include break-below(sml) {
    .gb-container-c528c9db {
        width: 100% !important;
    }
    .hero-pos-con {
        width: 100% !important;
        .gb-inside-container {
            padding: 0 !important;
        }
    }
}

@include break-below(sm) {
    .hero-home {
        min-height: 400px !important;
    }
    .hero-pages {
        min-height: 300px !important;
    }
}